<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide" style="height:250px">

      <div v-if="hotel.id" class="parallax parallax-top"
           v-bind:style="{ 'background-image': 'url(' + hotel.picturesURL[0] + ')' }"></div>
      <div v-else class="parallax parallax-top" style="background-image: url('assets/img/image6.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">{{ hotel.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section background-dark padding-top-bottom-smaller over-hide">
      <div class="section z-bigger">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
              <div class="amenities">
                <img src="assets/img/icons/1.svg" alt="">
                <p>no smoking</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
              <div class="amenities">
                <img src="assets/img/icons/2.svg" alt="">
                <p>big beds</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-md-0">
              <div class="amenities">
                <img src="assets/img/icons/3.svg" alt="">
                <p>yacht riding</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/4.svg" alt="">
                <p>free drinks</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/5.svg" alt="">
                <p>swimming pool</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/6.svg" alt="">
                <p>room breakfast</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top-bottom z-bigger">
      <div class="section z-bigger">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 mt-4 mt-lg-0" v-if="hotel.id">
              <div class="section">
                <div class="customNavigation rooms-sinc-1-2">
                  <a class="prev-rooms-sync-1"></a>
                  <a class="next-rooms-sync-1"></a>
                </div>
                <div id="rooms-sync1" class="owl-carousel">
                  <div class="item" v-for="(picture, index) in hotel.picturesURL" :key="'sync1_'+index">
                    <img class="carousel-img" :src="picture"/>
                  </div>
                </div>
              </div>
              <div class="section">
                <div id="rooms-sync2" class="owl-carousel">
                  <div class="item" v-for="(picsPreview, index) in hotel.picturesURL" :key="'sync2_'+index">
                    <img class="carousel-img-prev" :src="picsPreview"/>
                  </div>
                </div>
              </div>


              <div class="section pt-5">
                <h5 style="font-size: 1.3em;">{{ hotel.name }}</h5>

              </div>
              <div class="section pt-5">
                <h5 style="font-size: 1.5em;">Description</h5>
                <p class="mt-3" v-html="hotel.hotelDescription"></p>
              </div>
              <div class="section pt-4">
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-3" style="font-size: 1.5em;">Overview</h5>
                  </div>
                  <div class="col-lg-12" v-if="hotel.createdBy !== 'havemyroom'">
                    <p><strong class="color-black">Hotel Name:</strong> {{ hotel.name }}</p>
                    <p><strong class="color-black">Address:</strong> {{ hotel.address }}</p>
                    <p><strong class="color-black">Check In Date:</strong> {{
                        get_readable_dates(search_params.checkIn)
                      }}</p>
                    <p><strong class="color-black">Check Out Date:</strong> {{
                        get_readable_dates(search_params.checkOut)
                      }}</p>
                  </div>
                  <div class="col-lg-12" v-else>
                    <p><strong class="color-black">Hotel Name:</strong> {{ hotel.name }}</p>
                    <p><strong class="color-black">Address:</strong> {{ hotel.address }}</p>
                    <p><strong class="color-black">Check In Date:</strong> {{
                        get_readable_dates(search_params.checkIn)
                      }}</p>
                    <p><strong class="color-black">Check Out Date:</strong> {{
                        get_readable_dates(search_params.checkOut)
                      }}</p>
                    <p><strong class="color-black">Price per night:</strong>&euro;{{ get_round_up_price(hotel.price) }}</p>
                    <a class="mt-1 btn btn-primary" href="javascript:void(0)" @click="updateRoom(hotel)">book now &euro;{{
                        hotel.price
                      }}</a>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-6 order-first order-lg-last result-items">
              <div class="section p-4">
                <template v-if="isLoading">
                  <div class="vld-parent">
                    <h5 class="text-center">Searching for Rooms in {{ active_hotel.name }} <i
                      class="fas fa-spinner fa-spin"></i></h5>
                  </div>
                </template>
                <template v-else-if="rooms.length > 0">
                  <h5 class="text-center mb-2" style="font-size: 1.2em;">We have {{rooms.length}} list of Available Rooms</h5>
                  <div class="row mb-2" v-for="(room, index) in rooms" :key="index">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="room-box background-grey">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="room-name">
                                            <span>
                                                            <s class="mr-2"
                                                               v-if="room.originalPrice">{{
                                                                get_currency_symbol(room.currency) || '&euro;'
                                                              }}{{
                                                                get_round_up_price(room.originalPrice)
                                                              }} </s>
                                                            {{
                                                get_currency_symbol(room.currency) || '&euro;'
                                              }}{{ get_round_up_price(room.price) }}
                                                        </span>
                            </div>
                            <div class="room-per text-warning">
                              <i class="fa fa-star" v-for="count in Number.parseInt(room.rating)" :key="count"></i>
                            </div>
                            <div class="room-price">


                            </div>
                            <div class="img-container img-landscape">
                              <img :src="get_shuffled_array(room.picturesURL)" alt="">
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="room-box-in">
                              <h5 class="">{{ room.name }}</h5>
                              <!--                      room.roomDescription.split(" ").splice(0, 20).join(" ") -->
                              <p class="mt-3" v-if="room.hotelDescription">{{ room.hotelDescription }}</p>
                              <div class="room-icons mt-4 pt-4">
                                <img src="assets/img/2.svg" alt="">
                                <a href="javascript:void(0)" @click="updateRoom(room)">full info</a>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>

                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col-lg-12">
                      <h5 class="text-center text-muted">
                        Unfortunately we are unable to load rooms for this hotel
                      </h5>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Footer/>

  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters} from 'vuex'
import methodMixins from "../../utils/mixins/methodMixins";

export default {
  name: 'HotelInfo',
  data() {
    return {}
  },
  components: {
    Navbar,
    Footer
  },
  mixins: [methodMixins],
  computed: {
    ...mapGetters({
      hotel: 'hotel/getActiveHotel',
      search_params: 'room/get_search_params',
      rooms: 'room/get_rooms_loaded',
      active_hotel: 'hotel/getActiveHotel',
      isLoading: 'room/room_is_loading',
    })
  },
  methods: {
    async updateRoom(hotel) {
      await this.$store.commit('room/set_single_room', hotel)
      await this.$store.commit('room/set_room_is_hot_deal', false)
      if (hotel.createdBy === 'havemyroom') {
        await this.$router.push({name: 'Payment'})
      } else {
        await this.$router.push({name: 'roomsgallery'})
      }
    },
    async proceedToRooms(hotel) {
      const {search_params} = this;
      if (hotel.createdBy === 'impala') {
        return this.$store.dispatch('room/searchRoomsFromImpala', {
          hotelID: hotel.id,
          search_params
        });
      } else if (hotel.createdBy === 'rakuten') {
        return this.$store.dispatch('room/search_rakuten_hotel_rooms', hotel.id);
      }
    },
    get_readable_date: (timeStamp) => methodMixins.methods.get_readable_datetime(timeStamp),
  },
  mounted() {
    let hotel = this.hotel
    this.proceedToRooms(hotel)
    var sync1 = $("#rooms-sync1");
    var sync2 = $("#rooms-sync2");
    var sync3 = $("#rooms-sync3");
    var sync4 = $("#rooms-sync4");

    sync1.owlCarousel({
      singleItem: true,
      slideSpeed: 400,
      transitionStyle: "goDown",
      pagination: false,
      autoPlay: 6000,
      afterAction: syncPosition
    });
    sync2.owlCarousel({
      items: 5,
      itemsDesktop: [1199, 4],
      itemsDesktopSmall: [979, 3],
      itemsTablet: [768, 3],
      itemsMobile: [479, 2],
      pagination: false,
      responsiveRefreshRate: 100,
      afterInit: function (el) {
        el.find(".owl-item").eq(0).addClass("synced");
      }
    });
    sync3.owlCarousel({
      singleItem: true,
      slideSpeed: 400,
      transitionStyle: "goDown",
      pagination: false,
      autoPlay: 6000,
      afterAction: syncPosition1
    });
    sync4.owlCarousel({
      items: 5,
      itemsDesktop: [1199, 4],
      itemsDesktopSmall: [979, 3],
      itemsTablet: [768, 3],
      itemsMobile: [479, 2],
      pagination: false,
      responsiveRefreshRate: 100,
      afterInit: function (el) {
        el.find(".owl-item").eq(0).addClass("synced");
      }
    });

    // eslint-disable-next-line no-unused-vars
    function syncPosition(el) {
      var current = this.currentItem;
      sync2
        .find(".owl-item")
        .removeClass("synced")
        .eq(current)
        .addClass("synced")
      if (sync2.data("owlCarousel") !== undefined) {
        center(current)
      }
    }

    function center(number) {
      var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
      var num = number;
      var found = false;
      for (var i in sync2visible) {
        if (num === sync2visible[i]) {
          found = true;
        }
      }
      if (found === false) {
        if (num > sync2visible[sync2visible.length - 1]) {
          sync2.trigger("owl.goTo", num - sync2visible.length + 2)
        } else {
          if (num - 1 === -1) {
            num = 0;
          }
          sync2.trigger("owl.goTo", num);
        }
      } else if (num === sync2visible[sync2visible.length - 1]) {
        sync2.trigger("owl.goTo", sync2visible[1])
      } else if (num === sync2visible[0]) {
        sync2.trigger("owl.goTo", num - 1)
      }
    }

    // eslint-disable-next-line no-unused-vars
    function syncPosition1(el) {
      var current = this.currentItem;
      $("#rooms-sync4")
        .find(".owl-item")
        .removeClass("synced")
        .eq(current)
        .addClass("synced")
      if ($("#rooms-sync4").data("owlCarousel") !== undefined) {
        center1(current)
      }
    }

    function center1(number) {
      var sync4visible = sync4.data("owlCarousel").owl.visibleItems;
      var num = number;
      var found = false;
      for (var i in sync4visible) {
        if (num === sync4visible[i]) {
          found = true;
        }
      }
      if (found === false) {
        if (num > sync4visible[sync4visible.length - 1]) {
          sync2.trigger("owl.goTo", num - sync4visible.length + 2)
        } else {
          if (num - 1 === -1) {
            num = 0;
          }
          sync2.trigger("owl.goTo", num);
        }
      } else if (num === sync4visible[sync4visible.length - 1]) {
        sync2.trigger("owl.goTo", sync4visible[1])
      } else if (num === sync4visible[0]) {
        sync2.trigger("owl.goTo", num - 1)
      }
    }

    $("#rooms-sync2").on("click", ".owl-item", function (e) {
      e.preventDefault();
      var number = $(this).data("owlItem");
      sync1.trigger("owl.goTo", number);
    });
    $("#rooms-sync4").on("click", ".owl-item", function (e) {
      e.preventDefault();
      var number = $(this).data("owlItem");
      sync3.trigger("owl.goTo", number);
    });
  }
}
</script>

<style scoped>
.hero-text {
  font-size: 2em;
}

.carousel-img {
  height: 500px !important;
}

.carousel-img-prev {
  height: 100px !important;
}

.result-items {
  height: 1000px;
  max-height: 1000px;
  overflow-y: scroll
}

.img-container {
  width: 350px;
  border: none;
  overflow: hidden;
}

.img-landscape {
  height: 200px;
}

.hero-text {
  font-size: 2em;
}

.result-items {
  height: 700px;
  max-height: 700px;
  overflow-y: scroll
}

.room-box .img-container {
  width: 100%;
}

.room-name {
  background-color: rgba(0, 0, 0, .5);
  padding: 2px
}

.room-box-in h5 {
  font-size: 1.0em;
}
</style>
